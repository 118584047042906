import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Components
import Container from "../../Container";
import Row from "../../Row";

// Utils
import classNames from "classnames/bind";
import { Color, responsive, rem, yellowUnderline } from "../../../utils/style";

// Styled Elements

const ContentImageWrapper = styled.div`
  flex: 0 1 auto;
  padding-bottom: 56px;

  .image-offset {
    ${responsive.sm`
      margin-top: 40px;
    `};

    ${responsive.md`
      margin-top: 80px;
    `};
  }

  .img-style-left {
    user-select: none;
    user-drag: none;
    pointer-events: none;
    touch-callout: none;
    position: absolute;
    height: 181px; //delete with vqa
    width: 310px;
    left: 0;

    ${responsive.sm`
      height: 187px; //delete in vqa
      width: 310px;
    `};

    ${responsive.md`
      height: 250px; //delete in vqa
      width: 365px;
    `};

    ${responsive.lg`
      height: 456px; //delete in vqa
      width: 668px;
    `};
  }
  .img-style-right {
    user-select: none;
    user-drag: none;
    pointer-events: none;
    touch-callout: none;
    position: absolute;
    height: 181px; //delete with vqa
    width: 310px;
    right: 0;

    ${responsive.sm`
      height: 187px; //delete in vqa
      width: 310px;
    `};

    ${responsive.md`
      height: 249px; //delete in vqa
      width: 365px;
    `};

    ${responsive.lg`
      height: 456px; //delete in vqa
      width: 668px;
    `};
  }
`;

const StatsContainer = styled.div`
  margin-top: 200px;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  ${responsive.sm`
    margin-top: 0px;
    padding-left: 0 !important;
  `};

  &.text-offset {
    ${responsive.sm`
      margin-top: 40px !important;
    `};

    ${responsive.md`
      margin-top: 80px !important;
    `};
  }

  div {
    &:first-of-type {
      p {
        &:first-of-type {
          &::after {
            content: " ";
            height: 4px;
            width: 66px;
            position: absolute;
            background-color: ${Color.ritualBlue};
            top: calc(50% - 3px);
            left: -100px;
          }
        }
      }
    }
  }

  p {
    position: relative;
    font-weight: 300;
    font-size: ${rem(24)};
    line-height: ${rem(30)};
    letter-spacing: -0.01em;
    margin-bottom: 0;
    padding-bottom: 80px;

    ${responsive.md`
      font-size: ${rem(32)};
      line-height: ${rem(40)};
    `};

    ${yellowUnderline}

    &:nth-child(odd) {
      font-weight: 500;
      font-size: ${rem(48)};
      line-height: ${rem(48)};
      padding-bottom: 32px;

      ${responsive.md`
        font-size: ${rem(80)};
        line-height: ${rem(80)};
      `};
    }

    &.stat {
      sup {
        font-size: ${rem(16)};
        top: -1.25rem;

        ${responsive.md`
          top: -2.5rem;
        `};
      }
    }
  }

  span {
    font-weight: 300;
    font-size: ${rem(16)};
    line-height: ${rem(20)};
    letter-spacing: -0.01em;
    margin-bottom: 0;
    padding: 16px 0;

    ${responsive.md`
      font-size: ${rem(20)};
      line-height: ${rem(30)};
      padding: 24px 0;
    `};

    &.source {
      font-size: ${rem(12)};
      line-height: ${rem(16)};
      opacity: 0.32;
    }
  }
`;

const Stats = ({ content }) => {
  const { image, data, imageAlignment, offset } = content;
  const stats = data?.stats;

  if (!stats || !image) return null;

  return (
    <div>
      <Container style={{ overflow: "visible" }}>
        <Row>
          <StatsContainer
            className={classNames("col-12 col-8", {
              "col-sm-5 offset-sm-7": imageAlignment === "left",
              "col-sm-5": imageAlignment === "right",
              "text-offset": offset === "text-offset",
            })}
          >
            {stats.map((statistic, index) => {
              const { stat, description, source } = statistic;

              if (!stat || !description) return;

              return (
                <div key={`stats-${index}`}>
                  <p
                    className="stat"
                    dangerouslySetInnerHTML={{ __html: stat }}
                  ></p>
                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  {source && (
                    <span
                      className="source"
                      dangerouslySetInnerHTML={{ __html: source }}
                    ></span>
                  )}
                </div>
              );
            })}
          </StatsContainer>
          <ContentImageWrapper>
            <GatsbyImage
              className={classNames({
                "img-style-left": imageAlignment === "left",
                "img-style-right": imageAlignment === "right",
                "image-offset": offset === "image-offset",
              })}
              Ï
              image={getImage(image)}
              fadeIn={true}
              alt={image.description}
              style={{
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
          </ContentImageWrapper>
        </Row>
      </Container>
    </div>
  );
};

export default Stats;
